import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { pipesToSpan } from "../util/common";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import HubspotForm from "react-hubspot-form";
import useFooter from "../hooks/useFooter";
import Arrow from "../images/drop_up.svg";

function Landing({ data: { node }, path }) {
  const { title1, description, iframe } = node;

  const { cards, hubspot, faq, micro } = node.relationships;

  const logos = node.relationships.logos.map((item, index) => ({
    ...item,
    alt: node.logos[index].alt,
  }));

  const seo = getMetatags(
    node.metatag,
    node.relationships.OGImage?.localFile?.publicURL
  );

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow",
  });

  return (
    <Layout
      showCta={false}
      showMenus={false}
      showFooterMenus={true}
      showHeader={false}
      showFooter={false}
    >
      <Seo {...seo} />
      <div className="mt-20" />
      <Hero title={title1} description={description} />

      <div className="space-y-10 lg:space-y-[88px] pb-4 lg:pb-8">
        <SectionIframe iframe={iframe} />
        <SectionCustemers items={logos} />
        <Hubspot {...{ ...hubspot, path: path , title: "Book a Demo"}} />
        <SectionCards items={cards} />

        <SectionMicroCards items={micro} />
        <SectionFaq {...faq} items={[]} />
      </div>

      <section>
        <SectionFooter />
      </section>
    </Layout>
  );
}

const Hero = ({ title, description }) => {
  const titleHtml = pipesToSpan(title, "text-blue font-bold");

  return (
    <section className="pb-12 lg:pb-[72px]">
      <div className="container px-0 lg:px-6">
        <div className="">
          <div className="text-[#28293D] space-y-5 lg:space-y-10  mx-auto px-6 lg:px-0 text-center">
            <Link to="/" className="text-center mx-auto ">
              <img
                src="/logo-gradblue-dark.svg "
                alt="sigthfull"
                className="inline-block"
              />
            </Link>
            <h1
              className="text-[35px] lg:text-[60px] lg:leading-[65px] font-bold text-center "
              dangerouslySetInnerHTML={{ __html: titleHtml }}
            />

            <div
              className="text-xl lg:text-2xl font-normal lg:leading-[36px] text-center lg:w-[990px] mx-auto"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionIframe = ({ iframe }) => {
  return (
    <section className="lg:px-32">
      <div className="px-0 lg:px-6">
        <div
          className="h-[400px] lg:h-[800px] "
          dangerouslySetInnerHTML={{ __html: iframe }}
        />
      </div>
    </section>
  );
};

const Hubspot = ({ portal_id, form_id, title, sub_title, path }) => {
  const ready = (form) => {
    const moveToContainer = (form, classes, container) => {
      for (var x = 0; x < classes.length; x++) {
        var className = classes[x];
        var e = form.getElementsByClassName(className).item(0);
        container.appendChild(e);
      }
    };

    // create a new div element
    const div1 = document.createElement("div");
    div1.setAttribute("class", "grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-8 text-[#8F90A6]");

    const div2 = document.createElement("div");
    div2.setAttribute("class", "pt-4 text-[#8F90A6]");

    const form0 = document.getElementsByClassName("hs-form-private").item(0);

    moveToContainer(
      form0,
      ["hs-firstname", "hs-lastname", "hs-email", "hs-submit"],
      div1
    );
    moveToContainer(
      form0,
      ["hs-receive_news", "legal-consent-container"],
      div2
    );


    form0.appendChild(div1);
    form0.appendChild(div2);


    var e = form0.getElementsByClassName('legal-consent-container').item(0);
    e.setAttribute('style', 'color:#8F90A6')
  };

  const redirect = (path + "/thank-you").replace("//", "/");

  return (
    <section
      className="py-10 lg:py-16 bg-cover bg-no-repeat"
      style={{ backgroundImage: "url(/background/bg-pattern-2.png)" }}
    >
      <div className="container " id="the-form">
        <div className="container p-0 lg:p-0 ">
          <div className="text-grey rounded-xl  px-1 lg:px-0  max-w-fit space-y-3 lg:mx-auto lg:space-y-6">
            <h2 className=" text-3xl lg:subtitle-m-bold font-medium text-center">
              {title}
            </h2>
            {/* <p className=" text-sm text-center">{sub_title}</p> */}
            <HubspotForm
              portalId={portal_id}
              formId={form_id}
              //cssRequired=""
              css=" " // !important
              cssClass="sigthfull-hubspot"
              submitButtonClass="sigthfull-hubspot-landing-submit-btn c-btn  "
              errorClass="sigthfull-hubspot-error"
              errorMessageClass="sigthfull-hubspot-error-message"
              //onSubmit={(e) => console.log('Submit!')}
              //onFromSubmit={(e) => console.log('on form submit')}
              //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
              onReady={ready}
              //loading={<div>Loading...</div>}
              redirectUrl={redirect}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionCards = ({ title, items }) => {
  const blocks = items.map((item, index) => {
    const html = pipesToSpan(item.description, "text-blue font-bold");
    return (
      <div className="p-6 space-y-4  bg-[#F9FAFB] rounded-[16px]" key={index}>
        <div className="h-[126px]">
          <img
            src={item.relationships.image.localFile.publicURL}
            alt={item.image.alt}
            className="inline-block"
          />
        </div>
        <div className="text-lg" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  });

  return (
    <section className="">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-0 lg:gap-10 ">
          {blocks}
        </div>
      </div>
    </section>
  );
};

const SectionCustemers = ({ title, items }) => {
  const blocks = items.map((item, index) => (
    <div className="w-[150px] lg:w-[207px] " key={index}>
      <img
        src={item?.localFile?.publicURL}
        alt={item?.alt}
        className="inline-block"
      />
    </div>
  ));

  return (
    <section className="">
      <div className="container ">

        <div className="flex flex-row justify-around gap-0 hidden lg:flex items-center">
          {blocks}
        </div>

        <div className="flex flex-row flex-wrap lg:flex-row justify-center lg:gap-0 gap-2 lg:space-y-0 lg:space-x-8 lg:hidden">
          {blocks}
        </div>
      </div>
    </section>
  );
};

const SectionMicroCards = ({ title, items }) => {
  //console.log(items);

  const blocks = items.map((item, index) => (
    <div
      className="space-y-4 lg:space-y-6 text-center lg:text-left"
      key={index}
    >
      <div className="p-2.5">
        <img
          src={item.relationships.image.localFile.publicURL}
          alt={item.image.alt}
          className="h-[44px] w-[44px] inline-block"
        />
      </div>
      <h3 className="text-xl  lg:text-xl font-semibold lg:leading-[24px]">
        {item.label}
      </h3>
    </div>
  ));

  return (
    <section className="py-12  lg:py-12 bg-[#F9FAFB]">
      <div className="container space-y-8 lg:space-y-24">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-16 lg:gap-28">
          {blocks}
        </div>
      </div>
    </section>
  );
};

const SectionFaq = ({ title, relationships }) => {
  const [selected, setSelected] = useState(-1);

  const items = relationships.questions.map((item, index) => (
    <div className="border-b" key={index}>
      <div className="flex flex-row justify-between items-top  py-6 ">
        <div className="space-y-6 w-full">
          <button
            className="w-full text-left font-bold text-xl"
            onClick={() => setSelected(selected === index ? -1 : index)}
          >
            {item.question}
          </button>
          {selected === index && <div className="">{item.answer}</div>}
        </div>

        <div className="">
          <button
            onClick={() => setSelected(selected === index ? -1 : index)}
            className="flex flex-row justify-around items-center w-10 h-10  "
          >
            <img
              src={Arrow}
              className={
                "transition " + (selected === index ? "" : " rotate-180")
              }
              alt="arrow"
            />
          </button>
        </div>
      </div>
    </div>
  ));
  return (
    <section className="">
      <div className="container  ">
        <h2 className=" text-3xl font-medium mb-6">{title}</h2>
        {/* <div className="text-center my-6" dangerouslySetInnerHTML={{__html:description.processed}} /> */}
        <div>{items}</div>
      </div>
    </section>
  );
};

const SectionFooter = () => {
  const { legal, options } = useFooter();
  //const today = new Date();

  return (
    <div className="container py-4 lg:py-14 bg-white flex flex-col lg:flex-row justify-between text-sm lg:text-xs my-6 items-center lg:gap-0 gap-4">
      <div className="flex flex-col lg:flex-row gap-8 items-center">
        <div>
          <Link to="/" className="text-center mx-auto ">
            <img
              src="/logo-gradblue-dark.svg "
              alt="sigthfull"
              className="inline-block scale-75 lg:scale-100"
            />
          </Link>
        </div>
        <div className="flex flex-col lg:flex-row gap-6  lg:gap-4">
          {legal.map((item, index) => (
            <Link
              key={index}
              to={item.url}
              className="text-base text-[#28293D]"
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>

      <div className="flex flex-row text-[#28293D] border-blue lg:border-[#28293D] border-t lg:border-t-0 pt-6 mt-6 lg:pt-0 lg:mt-0 lg:border-l lg:pl-8 gap-8 lg:gap-4 w-full lg:w-fit justify-center lg:justify-end">
        <div className="uppercase text-sm font-semibold">{"follow us"}</div>
        {options.relationships.socials.map((m, i) => (
          <div key={i} className="">
            <a href={m.link.uri} target="_blank" rel="noreferrer">
              <img
                src={m.relationships.image.localFile.publicURL}
                alt={m.image.alt}
                width="18px"
                height="18px"
                loading="lazy"
                className=""
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Landing;

export const query = graphql`
  query landingPage($id: String!) {
    node: nodeLandingPage(id: { eq: $id }) {
      id
      title
      title1: field_title

      description: field_description3
      iframe: field_description2
      logos: field_logos {
        alt
        title
      }

      id
      path {
        alias
      }
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        faq: field_faq {
          ...ParagraphFaq
        }
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        logos: field_logos {
          localFile {
            publicURL
          }
        }
        micro: field_paragraph_repeater2 {
          label: field_label
          image: field_image {
            alt
            title
          }
          relationships {
            image: field_image {
              localFile {
                publicURL
              }
            }
          }
        }
        cards: field_paragraph_repeter {
          ...ParagraphImageAndText
        }
      }
    }
  }
`;
